<template>
  <div id="GestionClassNames">
    <v-container class="lighten-5">
      <v-container>
        <dashboard-core-app-bar />
      </v-container>
      <v-row style="margin-top: 20px">
        <v-breadcrumbs :items="items" divider=">"></v-breadcrumbs>
      </v-row>
      <v-col class="text-right" style="margin-top: 60px">
        <v-btn
          tile
          color="primary"
          @click="dialogAjoutNewClassName = true"
          :loading="loadingAdd"
        >
          <v-icon left> mdi-plus-thick </v-icon>
          Ajouter une nouvelle className
        </v-btn>
      </v-col>
      <v-card>
        <v-card-title>Liste des classNames</v-card-title>
        <v-data-table
          :footer-props="{
            'items-per-page-options': [5, 10, 15, 30],
          }"
          :headers="headers"
          :items="classNames"
          :search="search"
          :server-items-length="totalClassNamesCount"
          :options.sync="options"
          :loading="loading"
          class="elevation-1"
        >
          <template v-slot:item.uniqueId="{ item }">
            <span class="mr-2">#{{ item.uniqueId }}</span>
          </template>
          <template v-slot:item.categoryName="{ item }">
            <v-tooltip left max-width="250">
              <template #activator="{ on }">
                <span class="mr-2" v-on="on">{{ item.categoryName }}</span>
              </template>
              <span style="font-weight: bold">Sous categorie :</span>
              <span v-for="(test, i) in item.subCategoryName" :key="i">
                {{ test }},
              </span>
            </v-tooltip>
          </template>
          <template v-slot:item.className="{ item }">
            <span class="mr-2">{{ item.className }}</span>
          </template>
          <template v-slot:item.description="{ item }">
            <span class="mr-2">{{ item.description }}</span>
          </template>
          <template v-slot:item.modifier="{ item }">
            <v-icon @click="redirectPopup(item)"> mdi-pencil-outline </v-icon>
          </template>
        </v-data-table>
      </v-card>
      <v-row justify="center">
        <v-dialog
          v-model="dialogAjoutNewClassName"
          persistent
          max-width="600px"
        >
          <v-form ref="form" lazy-validation>
            <v-card>
              <v-card-title>
                <span class="text-h5">Nouvelle className</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="6" md="4">
                      <v-select
                        v-model="selectCategory"
                        :rules="rules"
                        :items="categoryName"
                        item-text="libelle"
                        item-value="id"
                        label="Categorie"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="6" md="12">
                      <v-select
                        v-model="selectSubCategory"
                        :rules="rules"
                        :items="subCategoryName"
                        item-text="libelle"
                        item-value="id"
                        label="Sous Categorie"
                        multiple
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="6" md="12">
                      <v-text-field
                        v-model="className"
                        :rules="rules"
                        label="ClassName"
                        multiple
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="12">
                      <v-textarea
                        v-model="description"
                        :rules="rules"
                        no-resize
                        clearable
                        required
                        label="Description"
                      ></v-textarea>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="annulerAddClassName()"
                >
                  Annuler
                </v-btn>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="validateFormAdd()"
                  :loading="loadingAdd"
                >
                  Ajouter
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-form>
        </v-dialog>
        <v-dialog
          v-model="dialogConfirmationAjoutClassName"
          persistent
          max-width="290"
        >
          <v-card>
            <v-card-text>
              Etes vous sur de vouloir ajouter la nouvelle className
              ?</v-card-text
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary darken-1"
                text
                @click="dialogConfirmationAjoutClassName = false"
              >
                Annuler
              </v-btn>
              <v-btn
                color="primary darken-1"
                text
                :loading="loadingAdd"
                @click="addClassName"
              >
                Confirmer
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
      <EditClassName
        :EditClassNameDialog.sync="EditClassNameDialog"
        :ClassName="sendClassNameInfo"
        :TotalClassNameCount="totalClassNamesCount"
        :ListeSubCategory="subCategoryName"
        :ListeCategory="categoryName"
        @Refresh="actualisationDataTable()"
        @CloseEditClassNameDialog="closeEditClassNameDialog()"
      />
    </v-container>
  </div>
</template>
<script>
import db from "../../../firebaseInit";
import NodesName from "../../utils/nodesName";
import FirebaseDB from "../../utils/firebaseDB";
import FunctionsUtils from "./../../utils/functions";
import nodesFieldName from "../../utils/nodesFieldName";
import firebaseForAuth from "firebase";
import EditClassName from "../components/core/EditClassName.vue";
export default {
  name: "GestionClassNames",
  components: {
    DashboardCoreAppBar: () =>
      import("../../materialDashboard/components/core/AppBar.vue"),
    EditClassName,
  },
  data() {
    return {
      items: [
        {
          text: "Dashboard",
          disabled: false,
          href: "/",
        },
      ],
      loading: false,
      loadingAdd: false,
      options: {},
      sortBy: [],
      totalClassNamesCount: 0,
      lastVisibleClassNames: null,
      totalClassNames: [],
      length: 5,
      classNames: [],
      search: "",
      categoryName: [],
      dialogAjoutNewClassName: false,
      subCategoryName: [],
      selectCategory: null,
      selectSubCategory: null,
      description: null,
      className: null,
      dialogConfirmationAjoutClassName: false,
      rules: [(v) => !!v || "Champ obligatoire"],
      sendClassNameInfo: [],
      EditClassNameDialog: false,
    };
  },

  methods: {
    //Permet lors de l'annulation d'ajout la reinitialisation des input
    annulerAddClassName() {
      this.dialogAjoutNewClassName = false;
      this.$refs.form.reset();
    },

    //Permet l'actualisation du tableau apres modification d'une className
    actualisationDataTable() {
      this.EditClassNameDialog = false;
      this.classNames = [];
      this.totalClassNames = [];
      this.lastVisibleClassNames = null;
      this.getClassNames();
    },
    //Permet l'actualisation du tableau apres l'ajout d'une className
    actualisationDataTableAfterAdd() {
      this.dialogAjoutNewClassName = false;
      this.dialogConfirmationAjoutClassName = false;
      this.classNames = [];
      this.totalClassNames = [];
      this.lastVisibleClassNames = null;
      this.getClassNames();
    },
    //Permet la fermeture du formulaire de modification d'une className au clique sur annuler
    closeEditClassNameDialog() {
      this.EditClassNameDialog = false;
    },
    async getClassNames() {
      this.loading = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;

      var query = db.collection(NodesName.PRODUCT_CLASS_NAME);
      this.totalClassNamesCount = await FirebaseDB.getCount(query);
      if (this.totalClassNames.length < this.totalClassNamesCount) {
        var querySnapshot = await FirebaseDB.getDocumentsWithPagination(
          query,
          this.lastVisibleClassNames,
          itemsPerPage,
          this.totalClassNamesCount
        );
        let i = 0;
        querySnapshot.forEach((className) => {
          if (Object.entries(className.data()).length !== 0) {
            const found = this.totalClassNames.some(
              (el) => el.uniqueId === className.uniqueId
            );
            if (!found) {
              this.totalClassNames.push({
                uniqueId: className.data().uniqueId,
                categoryId: className.data().category,
                description: className.data().description,
                className: className.data().className,
                subCategoryId: className.data().subCategory,
                modifier: "",
              });

              i++;

              if (querySnapshot.size == i) {
                this.lastVisibleClassNames = className;
              }
            }
          }
        });
      }
      for (var i = 0; i < this.totalClassNames.length; i++) {
        this.totalClassNames[i].categoryName = await FirebaseDB.getNameCategory(
          this.totalClassNames[i].categoryId
        );
      }
      for (var i = 0; i < this.totalClassNames.length; i++) {
        this.totalClassNames[i].subCategoryName =
          await FirebaseDB.getNameSubCategoryArray(
            this.totalClassNames[i].subCategoryId
          );
      }
      this.classNames = FunctionsUtils.customSortDataTable(
        this.totalClassNames,
        this.sortBy,
        sortDesc
      );

      this.classNames = FunctionsUtils.getItemsPerPageForDataTable(
        this.totalClassNames,
        itemsPerPage,
        page,
        this.totalClassNamesCountCount
      );

      this.loading = false;
    },
    async getNameCategory() {
      await db
        .collection(NodesName.PRODUCT_TO_SHARE_CATEGORIES)
        .where(nodesFieldName.DELETED, "==", false)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.categoryName.push({
              libelle: doc.data().libelle,
              id: doc.data().uniqueId,
            });
          });
        });
    },
    async getSubCategory() {
      await db
        .collection(NodesName.PRODUCT_TO_SHARE_SUBCATEGORIES)
        .where(nodesFieldName.DELETED, "==", false)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.subCategoryName.push({
              libelle: doc.data().libelle,
              id: doc.data().uniqueId,
            });
          });
        });
    },
    addClassName() {
      console.log("count debut", this.totalClassNamesCount);
      this.loadingAdd = true;
      var classNameRef = db.collection(NodesName.PRODUCT_CLASS_NAME).doc();
      const classNameAdd = {
        description: this.description,
        className: this.className,
        category: this.selectCategory,
        subCategory: this.selectSubCategory,
        uniqueId: classNameRef.id,
        deleted: false,
        creationDate: new Date(),
        deletedBy: null,
        createdBy: firebaseForAuth.auth().currentUser.uid,
        deletedDate: null,
        updateBy: null,
        updateDate: null,
      };
      classNameRef.set(classNameAdd).then(() => {
        this.classNames.push(classNameAdd);
        this.totalClassNamesCount += 1;
        this.$refs.form.reset();
        this.actualisationDataTableAfterAdd();
        this.loadingAdd = false;
      });
      console.log("count", this.totalClassNamesCount);
    },
    validateFormAdd() {
      if (this.$refs.form.validate()) {
        this.dialogConfirmationAjoutClassName = true;
      }
    },
    redirectPopup(item) {
      this.sendClassNameInfo = item;
      this.EditClassNameDialog = true;
    },
  },
  computed: {
    headers() {
      return [
        { text: "ID", value: "uniqueId" },
        { text: "Category", value: "categoryName" },
        { text: "ClassName", value: "className" },
        { text: "Description", value: "description" },
        { text: "Modifier", value: "modifier" },
      ];
    },
  },
  watch: {
    options: {
      handler() {
        this.getClassNames();
      },
      deep: true,
    },
  },
  created() {
    this.getNameCategory();
    this.getSubCategory();
  },
};
</script>
