<template>
  <v-layout row justify-center>
    <v-dialog v-model="EditClassNameDialog" persistent max-width="600">
      <v-form ref="form" lazy-validation>
        <v-card>
          <v-card-title>
            <span class="text-h5">Modification ClassName</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="6" md="4">
                  <v-select
                    v-model="ClassName.categoryId"
                    :items="ListeCategory"
                    item-text="libelle"
                    item-value="id"
                    label="Categorie"
                    :rules="rules.select"
                    clearable
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="12">
                  <v-select
                    v-model="ClassName.subCategoryId"
                    :items="ListeSubCategory"
                    item-text="libelle"
                    item-value="id"
                    label="Sous Categorie"
                    :rules="rules.select2"
                    multiple
                    clearable
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="12">
                  <v-text-field
                    v-model="ClassName.className"
                    label="ClassName"
                    multiple
                    :rules="rules.select"
                    clearable
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="12">
                  <v-textarea
                    v-model="ClassName.description"
                    no-resize
                    clearable
                    required
                    label="Description"
                    :rules="rules.select"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="closeEditClassNameDialog()"
            >
              Annuler
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              :loading="loading"
              @click="validateChamp()"
            >
              Enregistrer
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <!-- Dialog confirmation modification ClassName -->
    <v-dialog v-model="dialogModificationClassName" persistent max-width="290">
      <v-card>
        <v-card-text
          >Etes vous sur de vouloir appliquer les modifications ?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary darken-1"
            text
            @click="dialogModificationClassName = false"
          >
            Annuler
          </v-btn>
          <v-btn
            color="primary darken-1"
            text
            :loading="loading"
            @click="updateClassName()"
          >
            Confirmer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import NodesName from "../../../utils/nodesName";
import firebaseForAuth from "firebase";
import db from "../../../../firebaseInit";

export default {
  name: "EditClassName",
  data() {
    return {
      //rulesChamp: [(v) => !!v || "Champ obligatoire"],
      rules: {
        select: [(v) => !!v || "Champ obligatoire"],
        select2: [(v) => v.length > 0 || "Champ obligatoire"],
      },
      loading: false,
      dialogModificationClassName: false,
    };
  },

  methods: {
    validateChamp() {
      if (this.$refs.form.validate()) {
        this.dialogModificationClassName = true;
      }
    },
    //Actualisation du tableau aprés modification d'une ClassName
    actualisationDataTable() {
      this.$emit("Refresh");
    },
    //Fermeture de la boite de dialogue modification apres annulation
    closeEditClassNameDialog() {
      this.$emit("CloseEditClassNameDialog");
    },
    //Requette de mise à jour d'une ClassName
    updateClassName() {
      this.loading = true;
      db.collection(NodesName.PRODUCT_CLASS_NAME)
        .doc(this.ClassName.uniqueId)
        .update({
          description: this.ClassName.description,
          category: this.ClassName.categoryId,
          subCategory: this.ClassName.subCategoryId,
          className: this.ClassName.className,
          updatedDate: new Date(),
          updateBy: firebaseForAuth.auth().currentUser.uid,
        })
        .then(() => {
          this.dialogModificationClassName = false;
          this.actualisationDataTable();
          this.loading = false;
        });
    },
  },

  props: {
    ListeCategory: {
      type: Array,
    },
    ListeSubCategory: {
      type: Array,
    },
    ClassName: {
      required: true,
    },
    EditClassNameDialog: {
      type: Boolean,
    },
    TotalClassNameCount: {
      required: true,
    },
  },
};
</script>
